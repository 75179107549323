import { Box, Text, Flex, FormControl, FormLabel, Input, Textarea, Link } from '@chakra-ui/react';
import Container from 'components/common/Container';
import useTranslation from 'next-translate/useTranslation';
import FadeIn from 'components/transitions/FadeIn';
import React from 'react';
import NextLink from 'next/link';
import BlackFridayForm from 'components/common/WebForm/BlackFridayForm';
import Head from 'next/head';
import Countdown from 'components/common/Countdown';
import Image from 'next/image';

const BlackFriday = ({ data }) => {
  const { t } = useTranslation('blackfriday');

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const inputDate = new Date(data?.end_date);

  function titleBlackFriday() {
    return { __html: data?.text };
  }

  const formattedDate = `${
    months[inputDate.getMonth()]
  } ${inputDate.getDate()}, ${inputDate.getFullYear()} ${inputDate.getHours()}:${inputDate.getMinutes()}:${inputDate.getSeconds()}`;

  return (
    <>
      <FadeIn>
        {data?.cta?.url ? (
          <NextLink href={data?.cta?.url || '#'} passHref prefetch={false}>
            <Link textDecoration="none" target="_blank" _hover={'none'}>
              <Box maxH={'100%'} height={'369px'} position={'relative'} textAlign={'center'}>
                <Box className="above-768">
                  <Image objectFit="cover" layout="fill" src={data?.image[0]?.url} />
                </Box>
                <Box className="below-768">
                  <Image objectFit="cover" layout="fill" src={data?.mobile_image[0]?.url} />
                </Box>
                <Box
                  width={'100%'}
                  display="flex"
                  justifyContent={'center'}
                  alignItems="flex-end"
                  flexDir={'column'}
                  height="100%"
                  position={'absolute'}
                  top="0%"
                  left="0%"
                >
                  <Box display={'flex'} justifyContent={'center'} alignItems="flex-end" minH={'300px'} width={'100%'}>
                    {data?.end_date && <Countdown time={formattedDate} />}
                  </Box>
                </Box>
              </Box>
            </Link>
          </NextLink>
        ) : (
          <Box maxH={'100%'} height={'369px'} position={'relative'} textAlign={'center'}>
            <Box className="above-768">
              <Image objectFit="cover" layout="fill" src={data?.image[0]?.url} />
            </Box>
            <Box className="below-768">
              <Image objectFit="cover" layout="fill" src={data?.mobile_image[0]?.url} />
            </Box>
            <Box
              width={'100%'}
              display="flex"
              justifyContent={'center'}
              alignItems="flex-end"
              flexDir={'column'}
              height="100%"
              position={'absolute'}
              top="0%"
              left="0%"
            >
              <Box display={'flex'} justifyContent={'center'} alignItems="flex-end" minH={'300px'} width={'100%'}>
                {data?.end_date && <Countdown time={formattedDate} />}
              </Box>
            </Box>
          </Box>
        )}
      </FadeIn>
    </>
  );
};

export default BlackFriday;
